.text-dark-blue {
    color: $dark-blue;
}

.help-index-page {
    .list {
        a {
            color: $font-color-base;
        }
    }
}

.help-series-page {
    .help-series-table-page {
        .series-table table {
            thead tr {
                th {
                    color: white !important;
                }
            }

            tbody tr td {
                &.fixed {
                    font-size: 1.25rem;
                    font-weight: 500;
                }
            }
        }
    }

    .help-series-introduce-page {}

    .ex.v-card {

        .v-toolbar,
        .v-card__title {
            color: white;
        }
    }
}

.help-handicap-page {

    .help-handicap-01Game-page,
    .help-handicap-cricket-page {
        .v-data-table {
            thead tr {
                th {
                    color: white !important;
                }
            }
        }
    }
}
