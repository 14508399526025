/* series-table */

.v-application--wrap {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
  transition: all 0.1s;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

/* bar */

.toolbar {
  position: fixed;
  z-index: 9999;
}

.toolbar .theme--light.v-toolbar.v-sheet {
  background: linear-gradient(to right, #124A7B, #58ADE4) !important;
  color: white !important;
}

.toolbar .theme--light.v-toolbar.v-sheet i {
  color: white !important;
}

.toolbar .toolbar-title {
  position: absolute;
  width: 100%;
  margin-left: -16px;
  font-size: 1.2rem;
  letter-spacing: 0.25rem;
}

.toolbar a {
  z-index: 1000;
}

.has-toolbar-container {
  padding-top: 56px;
}

.text-dark-blue {
  color: #124A7B;
}

.help-index-page .list a {
  color: rgba(0, 0, 0, 0.6);
}

.help-series-page .help-series-table-page .series-table table thead tr th {
  color: white !important;
}

.help-series-page .help-series-table-page .series-table table tbody tr td.fixed {
  font-size: 1.25rem;
  font-weight: 500;
}

.help-series-page .ex.v-card .v-toolbar,
.help-series-page .ex.v-card .v-card__title {
  color: white;
}

.help-handicap-page .help-handicap-01Game-page .v-data-table thead tr th,
.help-handicap-page .help-handicap-cricket-page .v-data-table thead tr th {
  color: white !important;
}

/* border */

.bb-1 {
  border-bottom: 0.15rem solid;
}

.ba-1 {
  border: 0.15rem solid;
}

.b-gray {
  border-color: #e3e3e3;
}

/* li */

.no-list-style {
  padding-left: 12px !important;
}

.no-list-style li {
  list-style-type: none;
}

.help-index-page .list {
  height: 3rem;
  line-height: 3rem;
  letter-spacing: 0.15rem;
}

.help-series-page .divider,
.help-handicap-page .divider,
.help-sandbagging-page .divider {
  height: 0.25rem;
  background: linear-gradient(to right, #124A7B, white) !important;
  margin-left: -16px;
  margin-right: -16px;
}

.help-series-page .help-series-table-page .series-table div.v-data-table__wrapper:not(.auto-height) {
  height: calc(100vh - 232px);
  overflow-y: auto;
}

.help-series-page .help-series-table-page .series-table table thead tr th {
  position: sticky;
  top: 0;
  background-color: #124A7B;
}

.help-series-page .help-series-table-page .series-table table thead tr th:first-child {
  z-index: 2;
  left: 0;
}

.help-series-page .help-series-table-page .series-table table tbody tr td.fixed {
  position: sticky;
  left: 0;
}

.help-series-page .help-series-table-page .series-table table tbody tr.c td {
  background-color: #b4dfe2;
}

.help-series-page .help-series-table-page .series-table table tbody tr.c td.fixed {
  color: #5f979b;
}

.help-series-page .help-series-table-page .series-table table tbody tr.b td {
  background-color: #a9d7b9;
}

.help-series-page .help-series-table-page .series-table table tbody tr.b td.fixed {
  color: #6ba979;
}

.help-series-page .help-series-table-page .series-table table tbody tr.a td {
  background-color: #eddd8e;
}

.help-series-page .help-series-table-page .series-table table tbody tr.a td.fixed {
  color: #9e8a5f;
}

.help-series-page .help-series-table-page .series-table table tbody tr.s td {
  background-color: #f0acad;
}

.help-series-page .help-series-table-page .series-table table tbody tr.s td.fixed {
  color: #9e5b59;
}

.help-series-page .help-series-table-page .series-table table th,
.help-series-page .help-series-table-page .series-table table td {
  white-space: nowrap;
  text-align: center !important;
  border-right: 1px solid white !important;
  border-bottom: 1px solid white !important;
}

.help-series-page .help-series-table-page .download-warp {
  padding: 16px;
  background-color: white;
  position: absolute;
  top: -9999px;
}

.help-series-page .help-series-introduce-page .type-item {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  background-image: url("../images/league_logo.png");
  position: relative;
}

.help-series-page .help-series-introduce-page .type-item:first-child {
  background-image: url("../images/fido_logo.png");
}

.help-series-page .help-series-introduce-page .type-item table {
  margin: 0 auto;
}

.help-series-page .help-series-introduce-page .type-item table tr td:nth-child(2) {
  width: 30px;
}

.help-series-page .help-series-introduce-page .type-item table tr td:first-child,
.help-series-page .help-series-introduce-page .type-item table tr td:last-child {
  max-width: calc((100% - 30px) / 2);
}

.help-series-page .help-series-introduce-page .type-item table tr td svg {
  height: 100%;
  position: absolute;
}

.help-series-page .help-series-introduce-page .type-item table tr td svg path {
  stroke: rgba(0, 0, 0, 0.6);
  stroke-width: 1px;
  fill-opacity: 0;
}

.help-series-page .ex.v-card {
  border: none;
  background: linear-gradient(to right, #124A7B, #58ADE4) !important;
  border-radius: 8px;
  padding: 2px;
}

.help-series-page .ex.v-card .v-toolbar {
  background: inherit;
}

.help-series-page .ex.v-card .v-card__text {
  background: white;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.help-handicap-page .help-handicap-01Game-page .v-data-table,
.help-handicap-page .help-handicap-cricket-page .v-data-table {
  background: linear-gradient(to right, #124A7B, #58ADE4) !important;
  border-radius: 0;
  table-layout: fixed;
}

.help-handicap-page .help-handicap-01Game-page .v-data-table td,
.help-handicap-page .help-handicap-01Game-page .v-data-table th,
.help-handicap-page .help-handicap-cricket-page .v-data-table td,
.help-handicap-page .help-handicap-cricket-page .v-data-table th {
  border-bottom-width: 0 !important;
  padding-left: 1px;
  padding-right: 0;
  padding-bottom: 1px;
}

.help-handicap-page .help-handicap-01Game-page .v-data-table td:last-child,
.help-handicap-page .help-handicap-01Game-page .v-data-table th:last-child,
.help-handicap-page .help-handicap-cricket-page .v-data-table td:last-child,
.help-handicap-page .help-handicap-cricket-page .v-data-table th:last-child {
  padding-right: 1px;
}

.help-handicap-page .help-handicap-01Game-page .v-data-table td div,
.help-handicap-page .help-handicap-01Game-page .v-data-table th div,
.help-handicap-page .help-handicap-cricket-page .v-data-table td div,
.help-handicap-page .help-handicap-cricket-page .v-data-table th div {
  height: 31px;
  line-height: 33px;
}

.help-handicap-page .help-handicap-01Game-page .v-data-table tbody tr td div,
.help-handicap-page .help-handicap-cricket-page .v-data-table tbody tr td div {
  background-color: white;
}

